import React, { createContext, useContext, useState } from "react";
import logog from "./../../assets/logog.svg"; // Adjust path as necessary
import profileIcon from "./../../assets/profile_icon.png"; // Adjust path as necessary
import NTNotification from "./../../assets/NTNotification.svg"; // Adjust path as necessary
import {
  Container,
  Navbar,
  Nav,
  Popover,
  OverlayTrigger,
  Card,
  Stack,
} from "react-bootstrap";
import "./Navbar.css";
import { NavLink, useNavigate } from "react-router-dom";
import LanguageUtility from "../../language/LanguageUtility";
// import { UserContext } from "../../Layout";

function SolarNavbar() {
  const navigate = useNavigate();
  // const [admin, setAdmin] = useState(false);
  // const adminRole = useContext(UserContext);
  function profileManagment() {
    // setAdmin(true);
    // sessionStorage.setItem('aType',true);
    // adminRole.setAdmin(true);
    navigate("/admin");
  }
  
  function Styles() {
    return (
      <style>{`
            .profile-admin : hover{
                color: grey !important;
                background
            }
            `}</style>
    );
  }

  function logout() {
    // Clear any necessary session or local storage data here
    navigate("/login");
  }
  // const profileView = () => (
  //   <Popover className="shadow border" style={{ width: "200px" }}>
  //     <Popover.Body className="p-0 m-0">
  //       <Card className="border-0 p-0 m-0">
  //         <Styles />
  //         <Card.Body>
  //           <Stack direction="vertical" gap={1}>
  //             <div
  //               className="fw-bold profile-admin"
  //               style={{ cursor: "pointer" }}
  //             >
  //               Profile
  //             </div>
  //             <div
  //               className="fw-bold profile-admin"
  //               onClick={() => profileManagment()}
  //               style={{ cursor: "pointer" }}
  //             >
  //               Administrator
  //             </div>
  //             <div
  //               className="fw-bold profile-admin"
  //               style={{ cursor: "pointer" }}
  //             >
  //               Change Password
  //             </div>
  //             <div
  //               className="fw-bold profile-admin"
  //               style={{ cursor: "pointer" }}
  //             >
  //               Contact Us
  //             </div>
  //             <div
  //               className="fw-bold profile-admin"
  //               onClick={logout}
  //               style={{ cursor: "pointer" }}
  //             >
  //               Logout
  //             </div>
  //           </Stack>
  //         </Card.Body>
  //         <Card.Footer style={{ color: "#708496" }}>
  //           App Version 3.02
  //         </Card.Footer>
  //       </Card>
  //     </Popover.Body>
  //   </Popover>
  // );

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className=" w-100 shadow-lg solor-navbar"
    >
      <Container className="ml-o mr-0 m-0 mw-100">
        <Navbar.Brand href="/">
          <div className="">
            <img src={logog} alt="Logo" />
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          {/* {adminRole.admin === false || adminRole.admin ===undefined ?  */}
          <Nav className="me-auto navbar-links flex-fill justify-content-center">
            <NavLink
              to="/dashboard/overview"
              className={({ isActive }) =>
                "navbar-links" + (isActive ? " navbar-link-active" : "")
              }
            >
              Overview
            </NavLink>
            <NavLink
              to="/dashboard/plants/Plants"
              end
              className={({ isActive }) =>
                "navbar-links" + (isActive ? " navbar-link-active" : "")
              }
            >
              Plants
            </NavLink>

            <NavLink
              to="/dashboard/maintenance"
              className={({ isActive }) =>
                "navbar-links" + (isActive ? " navbar-link-active" : "")
              }
            >
              Maintenance
            </NavLink>
            <NavLink
              to="/dashboard/analytics"
              className={({ isActive }) =>
                "navbar-links" + (isActive ? " navbar-link-active" : "")
              }
            >
              Analytics
            </NavLink>
            <NavLink
              to="/dashboard/reports"
              className={({ isActive }) =>
                "navbar-links" + (isActive ? " navbar-link-active" : "")
              }
            >
              Reports
            </NavLink>
          </Nav>
            {/* : <Nav className="me-auto navbar-links flex-fill justify-content-center"></Nav>} */}
          <Nav>
            <div className="navbar-icons">
              {/* <LanguageUtility /> */}
              <img
                src={NTNotification}
                alt="Bell Icon"
                className="navbar-bell-icon"
              />
              <OverlayTrigger
                trigger="click"
                placement="bottom"
                rootClose
                overlay={<Popover className="shadow border" style={{position:"relative", width: "200px" }}>
                  <Popover.Body className="p-0 m-0">
                    <Card className="border-0 p-0 m-0">
                      <Styles />
                      <Card.Body>
                        <Stack direction="vertical" gap={1}>
                          <div
                            className="fw-bold profile-admin"
                            style={{ cursor: "pointer" }}
                            onClick={()=>navigate('/profile')}
                          >
                            Profile
                          </div>
                          <div
                            className="fw-bold profile-admin"
                            onClick={() => profileManagment()}
                            style={{ cursor: "pointer" }}
                          >
                            Administrator
                          </div>
                          <div
                            className="fw-bold profile-admin"
                            style={{ cursor: "pointer" }}
                            onClick={()=>navigate('/password')}
                          >
                            Change Password
                          </div>
                          <div
                            className="fw-bold profile-admin"
                            style={{ cursor: "pointer" }}
                            onClick={()=>navigate('/queries')}
                          >
                           My Queries
                          </div>
                          <div
                            className="fw-bold profile-admin"
                            style={{ cursor: "pointer" }}
                            onClick={()=>navigate('contact')}
                          >
                            Contact Us
                          </div>
                          <div
                            className="fw-bold profile-admin"
                            onClick={logout}
                            style={{ cursor: "pointer" }}
                          >
                            Logout
                          </div>
                        </Stack>
                      </Card.Body>
                      <Card.Footer style={{ color: "#708496" }}>
                        App Version 3.02
                      </Card.Footer>
                    </Card>
                  </Popover.Body>
                </Popover>}
              >
                <img
                  src={profileIcon}
                  alt="Profile Icon"
                  className="navbar-profile-icon"
                />
              </OverlayTrigger>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default SolarNavbar;