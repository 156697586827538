import { Button, Col, Container, Form, Row } from "react-bootstrap";
import BottomHeaderBar from "./BottomHeaderBar";
import { useState } from "react";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
function ChangePassword() {
    function Styles() {
        return <style>{`
            .form-button{
                   background-color: #167db0;
                   color: white;
                   border-radius: 7px;
                   padding: 3px 20px 3px 20px;
                    font-family: 'Open Sans';
                    font-weight: bold;
                }

            .form-button:hover{
                background-color: white !important;
                color : black !important;
                border-color:#167db0 !important;
                }

            .form-button-sp{
                    padding: 1px 10px 1px 10px !important;
                    border-radius: 20px !important;
                }
            .profile {
              font-family: 'Open Sans';
                    font-weight: bold;
            }    
             .profile-labels{
             font-weight: normal !important;
             }       
             
             .profile .row {
             margin-bottom:5px;
             }
            `}</style>
    }
    const [password, setPassword] = useState();
    const [confirm, setConfirm] = useState();
    const [error, setError] = useState('');
    const [check, setCheck] = useState(true)

    const updatePassword = () => {
        if (password !== confirm) {
            setError("Passwords Do Not Match");
            setCheck(false)

        } else if (password === '' || confirm === '') {
            setCheck(false)
            setError("Passwords Shouldn't Be Empty");
        } else {
            setCheck(true)
            setError('')
            //success update to backend
        }
    }
    return (<>
        <Styles />
        <BottomHeaderBar />
        <Container className="profile gap-2 mt-4">
            <Row className="mt-2 mb-2 mb-4">
                <Col className="d-flex flex-row align-items-center justify-content-start">
                    <div>Change password</div>
                </Col>
                <Col className="d-flex flex-row align-items-center justify-content-end"></Col>
            </Row>
            {/* <Form validated={check}> */}
                <Form.Group controlId="newPassword">
                    <Row className="w-100">
                        <Col md={3}>
                            <Form.Label className="text-nowrap"> New Password  <StarOutlinedIcon style={{ color: "black", fontSize: "smaller" }} /></Form.Label>
                        </Col>
                        <Col md={4}>
                            <Form.Control className="profile-labels" value={password} name="password"  type="text" onChange={(e) => setPassword(e.currentTarget.value)} placeholder="***********" />
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group controlId="confirmPassword">
                    <Row className="w-100">
                        <Col md={3}>
                            <Form.Label className="text-nowrap">Confirm Password <StarOutlinedIcon style={{ color: "black", fontSize: "smaller" }} /> </Form.Label>
                        </Col>
                        <Col md={4}>
                            <Form.Control className="profile-labels"  value={confirm} name="confirmPassword" type="text" onChange={(e) => setConfirm(e.currentTarget.value)} placeholder="**********" />
                        </Col>
                    </Row>
                </Form.Group>
                <Row>
                    <Col md={3}>
                    </Col>
                    <Col md={3}>
                        {check === false ? <div>
                            {error}
                        </div> : ""
                        }
                    </Col>
                </Row>



                <div className="w-100 d-flex flex-row align-items-center justify-content-end">
                    <Button onClick={() => updatePassword()} className="ml-3 m-1 form-button">  Save </Button>
                </div>
            {/* </Form> */}
        </Container>
    </>)
}
export default ChangePassword;