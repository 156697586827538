import { useState, useEffect} from "react";
import { Button, Col, Form, Row, Stack, Table } from "react-bootstrap";
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';


function DeviceDetails({ plantId, setDeviceMore }) {
    const [devices, setDevices] = useState([]);
    const [makeData, setMakeData] = useState([]);
    const [formData, setFormData] = useState({
        'Device_id': '',
        'Plant_id': plantId,
        'Device_type': '',
        'Make': '',
        'Rating': '',
        'Quantity': '',
        'Serial_number': '',
        'Serial Nos': '',
        'System_date-time': ''

    })
    const [editDevice, setEditDevice] = useState(undefined);


    const columns = [
        // 'Device_id',
        'Plant_id',
        'Device_type',
        'Make',
        'Rating',
        'Quantity',
        'Serial_number',
        'Serial Nos',
        'System_date-time']
    const [deviceList, setDeviceList] = useState([
        {
            'Device_id': 1,
            'Plant_id': '3lkjfalf',
            'Device_type': 'solar Edge',
            'Make': 'Solar',
            'Rating': 'lkdsjfal',
            'Quantity': '200',
            'Serial_number': '38lksjl',
            'Serial Nos': '32039i',
            'System_date-time': '12/12/2024'

        },
        {
            'Device_id': 2,
            'Plant_id': '3lkjfalf',
            'Device_type': 'solar Edge',
            'Make': 'Solar',
            'Rating': 'lkdsjfal',
            'Quantity': '200',
            'Serial_number': '38lksjl',
            'Serial Nos': '32039i',
            'System_date-time': '12/12/2024'

        },
    ]);

      // Fetch Device Types from API
      useEffect(() => {
        const fetchDeviceTypes = async () => {
            try {
                const response = await fetch(
                    "https://solar-api.antsai.in/api/plant/getDeviceTypes",
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );
                if (response.ok) {
                    const data = await response.json();
                    // Correctly map the API response keys
                    const formattedDevices = data.map((item) => ({
                        device_id: item["Device Type ID"], // Map 'Device Type ID' to 'device_id'
                        device_name: item["Device Name"], // Map 'Device Name' to 'device_name'
                    }));
                    setDevices(formattedDevices); // Update state with API data
                } else {
                    console.error("Failed to fetch device types");
                }
            } catch (error) {
                console.error("Error fetching device types:", error);
            }
        };
    
        fetchDeviceTypes();
    }, []); // Runs only once when the component mounts

      // Fetch Makes from API
      useEffect(() => {
        const fetchMakes = async () => {
            try {
                const response = await fetch(
                    "https://solar-api.antsai.in/api/plant/fetchMakes",
                    {
                        method: "GET",
                        headers: { "Content-Type": "application/json" },
                    }
                );
                if (response.ok) {
                    const data = await response.json();
                    const formattedMakes = data.map((item) => ({
                        make_id: item["make_id"],
                        make_name: item["make_name"],
                    }));
                    setMakeData(formattedMakes);
                } else {
                    console.error("Failed to fetch make data");
                }
            } catch (error) {
                console.error("Error fetching makes:", error);
            }
        };

        fetchMakes();
    }, []);


    function handleChange(event) {
        const { name, value } = event.currentTarget;
        setFormData({ ...formData, [name]: value });
    }

    // const handleRowSelection = (e,rowId) => {     
    //     if(e.target.checked === false) {
    //     setEditDevice(undefined)
    //     updateFormEmptyData()
    //     }else{
    //     setEditDevice(deviceList.find((r) => r.Device_id === rowId))
    //     setFormData({...deviceList.find((r) => r.Device_id === rowId)})
    //     }
    // }

    const handleDeleteRow = (rowId) => {
        setDeviceList([...deviceList.filter((r) => r.Device_id !== rowId)])
    }

    const onAdd = () => {
        if (editDevice !==undefined) {
            const d = deviceList.findIndex((r) => r.Device_id ===editDevice.Device_id)
              if(d !==-1 ){
                const item=deviceList.find((r) => r.Device_id ===editDevice.Device_id);
                item.Device_type=formData.Device_type;
                item.Make=formData.Make;
                item.Quantity=formData.Quantity;
                item.Rating=formData.Rating;
                item["Serial Nos"]=formData["Serial Nos"];
                deviceList.splice(d,1,item);
               setDeviceList([...deviceList])
              }
        } else {
            setDeviceList([...deviceList,formData])
        }
        setEditDevice(undefined)
        updateFormEmptyData()
    }

    // const update = () => {

    //     setEditDevice(undefined)
    //     updateFormEmptyData()
    // }

    const update = () => {
        if (editDevice) {
            const updatedDeviceList = deviceList.map((device) =>
                device.Device_id === editDevice.Device_id
                    ? { ...device, ...formData } // Update the specific row with formData values
                    : device // Keep other rows unchanged
            );
            setDeviceList(updatedDeviceList); // Update the deviceList state
            setEditDevice(undefined); // Reset editDevice
            updateFormEmptyData(); // Clear the form fields
        }
    };
    

    function updateFormEmptyData(){
        setFormData({
            'Device_id': '',
            'Plant_id': plantId,
            'Device_type': '',
            'Make': '',
            'Rating': '',
            'Quantity': '',
            'Serial_number': '',
            'Serial Nos': '',
            'System_date-time': ''})
    }

    const handleRowSelection = (e, rowId) => {
        if (e.target.checked === false) {
            setEditDevice(undefined); // Clear editDevice when unchecked
            updateFormEmptyData();
        } else {
            const selectedDevice = deviceList.find((r) => r.Device_id === rowId);
            setEditDevice(selectedDevice); // Set editDevice when checked
            setFormData({ ...selectedDevice });
        }
    };

    return (<>
        <Row className="w-100 d-flex align-items-center justify-content-end"><div className="text-end"><button onClick={() => setDeviceMore(false)}><CloseTwoToneIcon /></button></div></Row>
        <Stack gap={3} direction="vertical">

            <Form.Group
                className="w-100"
                controlId="other_device"
            >
                <Row className="w-100 d-flex flex-row align-items-center justify-content-left">
                    <Col md={2}>
                        <Form.Label> Other Devices : </Form.Label>
                    </Col>
                    <Col md={4}>
                    <Form.Select
                        name="Device_type"
                        value={formData.Device_type}
                        onChange={(e) => handleChange(e)}
                        size="md"
                    >
                        <option value="">Select Device</option>
                        {devices.map((dev) => (
                            <option key={dev.device_id} value={dev.device_name}>
                                {dev.device_name}
                            </option>
                        ))}
                    </Form.Select>
                    </Col>
                </Row>
            </Form.Group>
            <Form.Group className="w-100" controlId="Make">
                    <Row className="w-100 d-flex flex-row align-items-center justify-content-left">
                        <Col md={2}>
                            <Form.Label> Make : </Form.Label>
                        </Col>
                        <Col md={4}>
                            <Form.Select
                                name="Make"
                                value={formData.Make}
                                onChange={(e) => handleChange(e)}
                                size="md"
                            >
                                <option value="">Select Make</option>
                                {makeData.map((make) => (
                                    <option key={make.make_id} value={make.make_name}>
                                        {make.make_name}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>
                    </Row>
                </Form.Group>
            <Form.Group controlId="Rating" className="w-100"  >
                <Row className="w-100">
                    <Col md={2}>
                        <Form.Label className="text-nowrap"> Rating{" "} <span>:</span> </Form.Label>
                    </Col>
                    <Col md={4}>
                        <Form.Control name="Rating" value={formData.Rating} onChange={(e) => handleChange(e)} type="string" required />
                    </Col>
                </Row>
            </Form.Group>
            <Form.Group controlId="Quantity" className="w-100"  >
                <Row className="w-100">
                    <Col md={2}>
                        <Form.Label className="text-nowrap"> Quantity{" "} <span>:</span></Form.Label>
                    </Col>
                    <Col md={4}>
                        <Form.Control name="Quantity" value={formData.Quantity} min={0} max={3000} onChange={(e) => handleChange(e)} type="number" required />
                    </Col>
                </Row>
            </Form.Group>
            <Form.Group controlId="Serial Nos" className="w-100"  >
                <Row className="w-100">
                    <Col md={2}>
                        <Form.Label className="text-nowrap"> Serial Nos{" "} <span>:</span></Form.Label>
                    </Col>
                    <Col md={4}>
                        <Form.Control name="Serial Nos" value={formData["Serial Nos"]} maxLength={255} onChange={(e) => handleChange(e)} type="textarea" required />
                    </Col>
                </Row>
            </Form.Group>
            <Row className="w-100">
                <div className="w-100 d-flex flex-row align-items-center justify-content-between">
                    {" "}
                    <Button
    onClick={onAdd}
    className="mr-3 m-1 form-button"
    disabled={!!editDevice} // Disable when editDevice is set
    style={{
        backgroundColor: editDevice ? "#e0e0e0" : "", // Gray background if disabled
        cursor: editDevice ? "not-allowed" : "pointer", // Not-allowed cursor if disabled
    }}
>
    Add
</Button>{" "}
<Button
    onClick={update}
    className="ml-3 m-1 form-button"
    disabled={!editDevice} // Disable when editDevice is undefined
    style={{
        backgroundColor: !editDevice ? "#e0e0e0" : "", // Gray background if disabled
        cursor: !editDevice ? "not-allowed" : "pointer", // Not-allowed cursor if disabled
    }}
>
    Update
</Button>
                </div>
            </Row>
        </Stack>


        <Table striped bordered hover responsive>
            <thead>
                <tr>
                    {columns.map((column) => (
                        <th key={column} >
                            {column.replace(/([A-Z])/g, " $1")
                                .replace(/^\w/, (c) => c.toUpperCase())} {/* Capitalizes the first letter */}

                        </th>
                    ))}
                    <th></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {deviceList.map((row) => (
                    <tr>
                        {columns.map((column) => (
                            <td >{row[column]}</td>

                        ))}
                        <td>
                            <div className="gap-2 d-flex flex-row align-items-center justify-content-end px-2">
                                Select <Form.Check type="checkbox" checked={editDevice!==undefined ? editDevice.Device_id===row.Device_id : false} onChange={(e) => handleRowSelection(e,row.Device_id)} />
                            </div>
                        </td>
                        <td> <div><button onClick={() => handleDeleteRow(row.Device_id)} className="border-0">Delete</button></div></td>
                    </tr>
                ))}
            </tbody>
        </Table>
    </>)
}
export default DeviceDetails;