import React, { useState, useEffect, useMemo, useContext, useRef } from "react";
import "./EntityTable.css";
// Importing icons
import updownIcon from "../../assets/updown_icon.png";
import filterIcon from "../../assets/filter-icon1.svg";
import settingIcon from "../../assets/Setting-icon.svg";
import searchIcon from "../../assets/search-icon.svg";
import editIcon from "../../assets/edit-icon.png"; // Assuming you've saved the edit icon as "edit-icon.png"
import { MessageContext } from "../../App";
import { useNavigate } from "react-router-dom";
import { Col, Form, OverlayTrigger, Popover, Row, Modal, Stack } from "react-bootstrap";
import Paginationcontrol from "./PaginationControl";
import NotInterestedOutlinedIcon from "@mui/icons-material/NotInterestedOutlined";

const EntityTable = ({ stateChanger, rowId }) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const searchInputRef = useRef(null); // Create a ref for the search bar
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State to manage dropdown visibility
  const [selectedColumns, setSelectedColumns] = useState({
    id: true,
    entityName: true,
    firstName: true,
    lastName: true,
    emailId: true,
    mobileNumber: true,
    namespace: false,
    country: false,
    state: false,
    district: true,
    pincode: false,
    deviceCount: false, // Ensure this is set to true
    expiryDate: false,  // Ensure this is set to true
    gstin: false,
  });
  const [selectedFilter, setSelectedFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [selectedRows, setSelectedRows] = useState([]);
  const [update, setUpdate] = useState(false);
  const [tableProps, setTableProps] = useState({
    rowsPerPage: 5,
    currentPage: 1,
    pageFirst: 1,
    pageLast: 5,
  });

  const entitySearchRef = useRef(null);
  useEffect(() => {
    if (entitySearchRef.current && selectedFilter !== "") {
      entitySearchRef.current.focus();
    }
  }, [selectedFilter])

  const [disableBox, setDisablekBox] = useState(false);

  const onMessageChange = useContext(MessageContext);
  const [showConfirmModal, setShowConfirmModal] = useState(false); // Modal visibility state
  const [selectedEntity, setSelectedEntity] = useState(null); // Selected entity for confirmation
  const [enable,setEnable]=useState(false);

  const navigate = useNavigate();

  // Retrieve userData from session storage
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const userEmail = userData?.email || "";
  console.log("User email from session storage:", userEmail); // Log to check email from session

  // Fetch data from the API
  const [data, setData] = useState([]);

  useEffect(() => {
    // Retrieve masterEntityId from session storage
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    const entityId = userData?.entityId || "";

    // Make fetch request with masterEntityId as a query parameter
    let api = `https://solar-api.antsai.in/api/entity/fetchAllRecords?entityid=${entityId}`
    if (disableBox) {
      api = `https://solar-api.antsai.in/api/entity/fetchDeletedEntities?entityid=${entityId}`
    }
    fetch(api,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          const formattedData = data.map((item) => ({
            id: item.id,
            entityName: item["Entity Name"],
            deviceCount: item["Device Count"], 
            expiryDate: item["Expiry Date"]
            ? new Date(item["Expiry Date"]).toLocaleDateString('en-GB') // Format as dd-mm-yyyy
            : "", // Handle null or undefined dates
            
            /*dd-mm-yyyy format*/
            // expiryDate: item["Expiry Date"]
            // ? (() => {
            //     const date = new Date(item["Expiry Date"]);
            //     const day = String(date.getDate()).padStart(2, "0");
            //     const month = String(date.getMonth() + 1).padStart(2, "0");
            //     const year = date.getFullYear();
            //     return `${day}-${month}-${year}`;
            //   })()
            // : "", // Handle null or undefined dates

            firstName: item["First Name"],
            lastName: item["Last Name"],
            emailId: item["Email Id"],
            mobileNumber: item["Mobile Number"],
            namespace: item["Namespace"],
            country: item["Country"],
            state: item["State"],
            district: item["District"],
            pincode: item["Pincode"],
            gstin: item["GSTIN"],
          }));
          console.log("Formatted Data for Table:", formattedData);
          console.log("Current Rows for Table:", currentRows);
          setData(formattedData);
        } else {
          console.error("Unexpected data format:", data);
          setData([]); // Set to empty array if no records found
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setData([]); // Set to empty array on error
      });
  }, [disableBox]);

  const handleSort = (column) => {
    let direction = "ascending";
    if (sortConfig.key === column && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key: column, direction });
  };

  const sortedData = useMemo(() => {
    let sortableData = [...data];
    if (sortConfig.key) {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [data, sortConfig]);

  const filteredData = useMemo(() => {
    return sortedData.filter((row) => {
      if (!selectedFilter || !searchQuery) return true;
      return row[selectedFilter]
        .toLowerCase()
        .startsWith(searchQuery.toLowerCase());
    });
  }, [sortedData, selectedFilter, searchQuery]);

  const navigateToEditPage = (e, rowData) => {
    e.stopPropagation();
    navigate("/admin/entity/edit", { state: { entityId: rowData.id } });
  };

  const tablePropsChange = (updatedProps) => {
    setTableProps(updatedProps);
  };

  const onRowChoose = (e, row) => {
    stateChanger(3);
    rowId(row);
  };

  const downloadCSV = () => {
    const csvRows = [];
    const headers = Object.keys(data[0] || {});
    csvRows.push(headers.join(","));

    for (const row of data) {
      const values = headers.map((header) => {
        const escaped = ("" + row[header]).replace(/"/g, '""'); // Escape double quotes by doubling them
        return `"${escaped}"`; // Wrap each value in quotes
      });
      csvRows.push(values.join(","));
    }

    const csvString = csvRows.join("\n");
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.setAttribute("download", "entities.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  // Pagination logic
  const currentRows = useMemo(() => {
    if (filteredData.length > 0) {
      return filteredData.slice(
        (tableProps.currentPage - 1) * tableProps.rowsPerPage,
        (tableProps.currentPage - 1) * tableProps.rowsPerPage +
        tableProps.rowsPerPage
      );
    }
    return [];
  }, [filteredData, tableProps]);

  const handleColumnSelect = (column) => {
    setSelectedColumns((prev) => ({ ...prev, [column]: !prev[column] }));
  };

  const disableRow = (e, entity) => {
    e.stopPropagation();
    setEnable(false)
    setSelectedEntity(entity); // Store selected entity for confirmation
    setShowConfirmModal(true); // Show confirmation modal
  };

  const enableRow = (e, entity) => {
    e.stopPropagation();
    setEnable(true);
    setSelectedEntity(entity);
    setShowConfirmModal(true);

  }

  const confirmDisable = async () => {
    try {
      const response = await fetch("https://solar-api.antsai.in/api/entity/disable",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
          },
          body: JSON.stringify({ entityid: selectedEntity.id }),
        }
      );

      if (response.ok) {
        onMessageChange({ type: "Success", info: "Entity disabled successfully." });
        setShowConfirmModal(false); // Close the modal on success
        // Add a delay before reloading to allow the success message to show
        setTimeout(() => {
          window.location.reload();
        }, 1000); // 1-second delay
      } else {
        const errorData = await response.json();
        console.error("Failed to disable entity:", errorData);
        onMessageChange({ type: 'Danger', info: `Failed to disable entity: ${errorData.message || "Unknown error"}` });
        setShowConfirmModal(false); // Close the modal in case of error
      }
    } catch (error) {
      console.error("Error disabling entity:", error);
      onMessageChange({ type: "Warning", info: "An error occurred while disabling the entity. Please try again later." });
      setShowConfirmModal(false); // Close the modal in case of error
    }
  };

  const confirmEnable = async () => {
    try {
      const response = await fetch("https://solar-api.antsai.in/api/entity/restoreEntity",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
          },
          body: JSON.stringify({ "entityid": `${selectedEntity.id}` }),
        }
      );

      if (response.ok) {
        onMessageChange({ type: "Success", info: `Entity ${selectedEntity.id} restored successfully.` });
        setShowConfirmModal(false); // Close the modal on success
        // Add a delay before reloading to allow the success message to show
        setTimeout(() => {
          window.location.reload();
        }, 1000); // 1-second delay
      } else {
        const errorData = await response.json();
        console.error("Failed to enable entity:", errorData);
        onMessageChange({ type: 'Danger', info: `Failed to enable entity: ${errorData.message || "Unknown error"}` });
        setShowConfirmModal(false); // Close the modal in case of error
      }
      setEnable(false);
    } catch (error) {
      setEnable(false)
      console.error("Error enabling entity:", error);
      onMessageChange({ type: "Warning", info: "An error occurred while enabling the entity. Please try again later." });
      setShowConfirmModal(false); // Close the modal in case of error
    }
  };

  // Handle filter selection
  const handleFilterSelect = (column) => {
    setSelectedFilter(column); // Set the selected filter
    setIsDropdownOpen(false); // Close the dropdown
    setTimeout(() => {
      if (searchInputRef.current) {
        searchInputRef.current.focus(); // Focus on the search bar
      }
    }, 0); // Ensure UI updates before focusing
  };

  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <div className="main-container">
        <Row className="d-flex flex-row align-items-center justify-content-between my-4">
          <Col></Col>
          <Col className="gap-3 d-flex flex-row align-items-center justify-content-end" >
            <span className="downloadLink" onClick={downloadCSV}>
              Download
            </span>
            <OverlayTrigger
              rootClose
              trigger="click"
              placement="left-start"
              show={isDropdownOpen} // Control dropdown visibility
              onToggle={(isOpen) => setIsDropdownOpen(isOpen)} // Sync state with dropdown
              overlay={
                <Popover>
                  <Popover.Body className="overlay-body-menu">
                    <ul>
                      {Object.keys(selectedColumns)
                        .filter((key) => selectedColumns[key])
                        .map((column) => (
                          <li
                            key={column}
                            onClick={() => handleFilterSelect(column)} // Call filter select handler
                          >
                            {column.charAt(0).toUpperCase() + column.slice(1)}
                          </li>
                        ))}
                    </ul>
                  </Popover.Body>
                </Popover>
              }
            >
              <img src={filterIcon} alt="filter" className="filter-icon" onClick={() => setIsDropdownOpen(!isDropdownOpen)} />
            </OverlayTrigger>

            <div style={{ position: 'relative' }} className=" w-auto">
              <input
                //  ref={searchInputRef}
                ref={entitySearchRef}
                id="entity_search"
                type="text"
                style={{
                  width: '341px',
                  height: '39px',
                  background: '#BBCCD9 0% 0% no-repeat padding-box',
                  borderRadius: '5px',
                  opacity: ' 0.51'
                }}
                className="search-input"
                placeholder={`Search by ${selectedFilter || "..."}`}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                disabled={!selectedFilter}
              />
              <img src={searchIcon} alt="search" className="search-icon" />
            </div>
          </Col>
        </Row>
        <div className="border rounded border-light-subtle">
  <div className="table-container">
    <table>
      <thead>
        <tr>
          {Object.keys(selectedColumns)
            .filter((key) => selectedColumns[key])
            .map((column) => (
              <th key={column} onClick={() => handleSort(column)}>
                {column === "id"
                  ? "Entity ID"
                  : column.replace(/([A-Z])/g, " $1").replace(/^\w/, (c) => c.toUpperCase())}
                <img
                  src={updownIcon}
                  alt="sort"
                  style={{ cursor: "pointer" }}
                />
              </th>
            ))}
          <th>
            <div className="gap-2 d-flex flex-row align-items-center justify-content-end">
              <OverlayTrigger
                rootClose
                trigger="click"
                placement="auto-start"
                overlay={
                  <Popover>
                    <Popover.Body className="overlay-body">
                      <div>
                        <label>
                          <input
                            type="checkbox"
                            checked={selectedColumns.id}
                            disabled
                          />{" "}
                          Entity ID
                        </label>
                        <label>
                          <input
                            type="checkbox"
                            checked={selectedColumns.entityName}
                            disabled
                          />{" "}
                          Entity Name
                        </label>
                        {Object.keys(selectedColumns).map(
                          (column) =>
                            column !== "id" &&
                            column !== "entityName" && (
                              <label key={column}>
                                <input
                                  type="checkbox"
                                  checked={selectedColumns[column]}
                                  onChange={() =>
                                    handleColumnSelect(column)
                                  }
                                />{" "}
                                {column.charAt(0).toUpperCase() +
                                  column.slice(1)}
                              </label>
                            )
                        )}
                        <label>
                          <input
                            type="checkbox"
                            checked={disableBox}
                            onChange={() => setDisablekBox(!disableBox)}
                          />{" "}
                          Disabled
                        </label>
                      </div>
                    </Popover.Body>
                  </Popover>
                }
              >
                <div>
                  <img
                    src={settingIcon}
                    alt="settings"
                    className="setting-icon"
                  />
                </div>
              </OverlayTrigger>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        {currentRows.length > 0 ? (
          currentRows.map((row) => (
            <tr key={row.id}>
              {Object.keys(selectedColumns)
                .filter((key) => selectedColumns[key])
                .map((column) => (
                  <td key={`${row.id}-${column}`}>
                    {row[column]}
                  </td>
                ))}
              <td className="delete-column">
                <div className="gap-2 d-flex flex-row align-items-center justify-content-end">
                  <img
                    src={editIcon}
                    alt="edit"
                    className="edit-icon"
                    onClick={(e) => onRowChoose(e, row.id)}
                  />
                  {row.emailId !== userEmail ? (
                    <NotInterestedOutlinedIcon
                      onClick={(e) =>
                        disableBox ? enableRow(e, row) : disableRow(e, row)
                      }
                      style={{ cursor: "pointer" }}
                      className={
                        disableBox ? "enable-icon" : "disable-icon"
                      } // Apply the appropriate class
                    />
                  ) : (
                    <span style={{ width: "24px" }}> </span> // Placeholder for alignment
                  )}
                </div>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td
              colSpan={Object.keys(selectedColumns).length + 1}
              style={{
                textAlign: "center",
                padding: "20px",
                color: "#666",
                fontSize: "16px",
              }}
            >
              {disableBox
                ? "No disabled records found."
                : "No active records found."}
            </td>
          </tr>
        )}
      </tbody>
    </table>
    {filteredData.length > 0 ? (
      <Paginationcontrol
        rowsCount={filteredData.length}
        tableProps={tableProps}
        tablePropsChange={tablePropsChange}
        alwaysShown={true}
      />
    ) : null}
  </div>
</div>

      </div>
      <div className="d-flex flex-column align-items-center justify-content-center">
        {/* Confirmation Modal */}
        <Modal
          show={showConfirmModal}
          onHide={() => setShowConfirmModal(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{`${enable? 'Enable' :'Disable'} Entity ?`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
           {`Are you sure you want to ${enable? 'Enable' :'Disable'} the entity ${selectedEntity?.entityName} ?`}
          </Modal.Body>
          <Modal.Footer>
            <button
              className="modal-button"
              onClick={() => setShowConfirmModal(false)}
              autoFocus // "No" as default focus
            >
              No
            </button>
            <button className="modal-button" onClick={() => enable ? confirmEnable() : confirmDisable()}>
              Yes
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default EntityTable;