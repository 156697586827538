import { APIProvider, Map, useMap, useMapsLibrary } from "@vis.gl/react-google-maps";
import { useEffect, useState } from "react";

function GMap({ltUpdate,setltUpdate, setLatLng, latlng, address, setAddress,addressUpdate,setAddressUpdate }) {
    const [location, setLocation] = useState({ lat: 12.936847666033527, lng: 77.57351512286992 });
     useEffect(()=>{

        },[location])

  
    const MyComponent = () => {
        const map = useMap("one-of-my-maps");
        const geocodeLibrary = useMapsLibrary('geocoding');
 
        const [geocodeService, setGeocodeServie] = useState(null);
        useEffect(() => {
            if (!geocodeLibrary || !map) return;
            setGeocodeServie(new geocodeLibrary.Geocoder())

        }, [geocodeLibrary])

        useEffect(() => {
            if (!geocodeService || !map) return;
            map.addListener('click', (e) => {
                const location = { lat: e.latLng.lat(), lng: e.latLng.lng() };
                setLocation(location)
                setLatLng(location)
                getAddressFromLatLang(location)
              
            })
            // getAddressFromLatLang({lat:lat,lng:lng})
        }, [geocodeService])



        useEffect(() => {
            if (!geocodeService || !map) return;
                if (ltUpdate) {
                    getAddressFromLatLang(latlng);
                } else if(addressUpdate) {
                    getLatLngFromAddress(address);
                }

        }, [ltUpdate, addressUpdate, geocodeService])

        const getAddressFromLatLang = (location) => {
            map.setCenter(location);
            map.setZoom(18)
            geocodeService.geocode({ location }, (results, status) => {
                if (status === 'OK') {
                    if (results[0]) {
                   
                        let country = "";
                        let region = "";
                        let state = "";
                        let district = "";
                        let addressOne = '';
                        let addressTwo = "";
                        let pincode = "";

                        results[0].address_components.forEach(component => {
                            const types = component.types;
                            if (types.includes('locality')) {
                                region = component.long_name; // City/Region
                            }
                            if (types.includes('administrative_area_level_1')) {
                                state = component.long_name; // State
                            }
                            if(types.includes('administrative_area_level_2')){
                                addressTwo=component.long_name;
                            }
                            if (types.includes('administrative_area_level_3')) {
                                district = component.long_name;
                            }
                            if (types.includes('country')) {
                                country = component.long_name; // Country
                            }
                            if (types.includes('postal_code')) {
                                pincode = component.long_name; // Postal code
                            }
                        });

                        setltUpdate(false);
                        setAddress({
                            'region': region,
                            'state': state,
                            'district': district,
                            'country': country,
                            // ...address,
                            'pincode': pincode,
                            'addressOne': results[0].formatted_address,
                            'addressTwo': addressTwo
                        });
                    

                    } else {
                        console.error('No results found');
                        setltUpdate(false);
                    }
                } else {
                    console.error('Geocoder failed due to: ' + status);
                    setltUpdate(false);
                }
            });       

        }
        const updateZoom=()=>{
            if(address.addressOne !=='' || address.addressTwo !=='' || address.pincode !==''){
                return 15;
            }else if(address.district !== ''){
                return 10;
            } else if( address.state !== '' || address.region !=='' ){
                return 6;
            } else if( address.country !==''){
                return 5;
            } else {
                return 3
            } 
        }
        const getLatLngFromAddress = (address) => {
            const a = `${address.region} ${address.country} ${address.state} ${address.district} ${address.addressOne} ${address.addressTwo} ${address.pincode}`;
            geocodeService.geocode({ address: a }, (results, status) => {
                if (status === 'OK') {
                    const location = results[0].geometry.location;
                    // setLocation({ lat: location.lat(), lng: location.lng() })
                    setAddressUpdate(false);
                    setLatLng({ lat: location.lat(), lng: location.lng() })                   
                    map.setCenter({ lat: location.lat(), lng: location.lng() })
                    map.setZoom(updateZoom())
                    // getAddressFromLatLang(location);
                } else {
                    setAddressUpdate(false);
                    console.error('Geocode was not successful for the following reason: ' + status);
                }
            });
   
        }
        return <></>
    }

   
    return (

        <APIProvider apiKey='AIzaSyBcm6IsWKNcYgMeQVWH1mbNzJoW0FvrZ90' language="en" region="en" >
            <Map id={'one-of-my-maps'}  reuseMaps defaultCenter={location} defaultZoom={3}  mapId="DEMO_MAP_ID" style={{ width: '100%', height: '60vh' }}></Map>
            <MyComponent />
        </APIProvider>
    )
}
export default GMap;