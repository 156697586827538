import { useEffect, useState } from "react";
import BottomHeaderBar from "./BottomHeaderBar";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import { Divider } from "@mui/material";


function Profile() {
    function Styles() {
        return <style>{`
            .form-button{
                   background-color: #167db0;
                   color: white;
                   border-radius: 7px;
                   padding: 3px 20px 3px 20px;
                    font-family: 'Open Sans';
                    font-weight: bold;
                }

            .form-button:hover{
                background-color: white !important;
                color : black !important;
                border-color:#167db0 !important;
                }

            .form-button-sp{
                    padding: 1px 10px 1px 10px !important;
                    border-radius: 20px !important;
                }
            .profile {
              font-family: 'Open Sans';
                    font-weight: bold;
            }    
             .profile-labels{
             font-weight: normal !important;
             }       
             
             .profile .row {
             margin-bottom:5px;
             }
            `}</style>
    }
    const [edit, setEdit] = useState(false);
    const [profile, setProfile] = useState({ name: 'A Gayitri', contactNumber: "9999999999", emailId: 'agayitri@gmail.com' });
    const [baddress, setBAddress] = useState({ businessName: 'Resedential', address: "#121, 4th croaa, 8 main Somepet", location: 'Namapur', pincode: '505001', region: 'Karimnagar', state: 'Telangana' });

    useEffect(() => {

    })

    const handleBusinessChange=(e)=>{
        const name=e.currentTarget.name;
        const value=e.currentTarget.name;

        setBAddress((prevData) => ({...prevData,[name]: value,}));
    }
    const handleUserChange=(e)=>{
        const name=e.currentTarget.name;
        const value=e.currentTarget.name;

        setProfile((prevData) => ({...prevData,[name]: value,}));
    }
    const updateProfileAddress = () => {
        // setEdit(false)
    }

    return (<>
        <Styles />
        <BottomHeaderBar />
        <Container className="profile gap-2">
            <Row className="mt-2 mb-2">
                <Col className="d-flex flex-row align-items-center justify-content-start">
                    <div>Profile</div>
                </Col>
                <Col className="d-flex flex-row align-items-center justify-content-end"></Col>
            </Row>
            <Row className="mt-2 mb-2">
                <Col className="d-flex flex-row align-items-center justify-content-start">
                    <div>Personal Information</div>
                </Col>
                <Col className="d-flex flex-row align-items-center justify-content-end">{!edit ? <Button onClick={() => setEdit(true)} className="ml-3 m-1 form-button">  Edit </Button> : <></>}  </Col>
            </Row>
            {!edit ? <>

                <Row className="w-100">
                    <Col md={3}>
                        <Form.Label className="text-nowrap "> Name  <StarOutlinedIcon style={{ color: "black", fontSize: "smaller" }} /></Form.Label>
                    </Col>
                    <Col md={4}>
                        <Form.Label className="text-nowrap profile-labels"> {profile.name}</Form.Label>
                    </Col>
                </Row>
                <Row className="w-100">
                    <Col md={3}>
                        <Form.Label className="text-nowrap"> Contact Number </Form.Label>
                    </Col>
                    <Col md={4}>
                        <Form.Label className="text-nowrap profile-labels"> {profile.contactNumber}</Form.Label>
                    </Col>
                </Row>
                <Row className="w-100">
                    <Col md={3}>
                        <Form.Label className="text-nowrap"> Email Id <StarOutlinedIcon style={{ color: "black", fontSize: "smaller" }} /></Form.Label>
                    </Col>
                    <Col md={4}>
                        <Form.Label className="text-nowrap profile-labels"> {profile.emailId}</Form.Label>
                    </Col>
                </Row>

                <Row><Divider className="m-2" sx={{ borderStyle: 'dotted', height: ' 5px', borderColor: 'black', borderWidth: '0px 0px 2px 0px' }} orientation="horizontal" /></Row>

                <Row className="w-100">
                    <Col md={3}>
                        <Form.Label className="text-nowrap"> Business Name  <StarOutlinedIcon style={{ color: "black", fontSize: "smaller" }} /></Form.Label>
                    </Col>
                    <Col md={4}>
                        <Form.Label className="text-nowrap profile-labels"> {baddress.businessName}</Form.Label>
                    </Col>
                </Row>
                <Row className="w-100">
                    <Col md={3}>
                        <Form.Label className="text-nowrap"> Address</Form.Label>
                    </Col>
                    <Col md={4}>
                        <Form.Label className="text-nowrap profile-labels"> {baddress.address},</Form.Label>
                    </Col>
                </Row>
                <Row className="w-100">
                    <Col md={3}>
                        <Form.Label className="text-nowrap"> Location </Form.Label>
                    </Col>
                    <Col md={4}>
                        <Form.Label className="text-nowrap profile-labels"> {baddress.location}</Form.Label>
                    </Col>
                </Row>
                <Row className="w-100">
                    <Col md={3}>
                        <Form.Label className="text-nowrap"> Pincode </Form.Label>
                    </Col>
                    <Col md={4}>
                        <Form.Label className="text-nowrap profile-labels"> {baddress.pincode}</Form.Label>
                    </Col>
                </Row>
                <Row className="w-100">
                    <Col md={3}>
                        <Form.Label className="text-nowrap"> Region </Form.Label>
                    </Col>
                    <Col md={4}>
                        <Form.Label className="text-nowrap profile-labels"> {baddress.region}</Form.Label>
                    </Col>
                </Row>
                <Row className="w-100">
                    <Col md={3}>
                        <Form.Label className="text-nowrap"> State </Form.Label>
                    </Col>
                    <Col md={9}>
                        <Form.Label className="text-nowrap profile-labels"> {baddress.state}</Form.Label>
                    </Col>
                </Row>

            </> : <>
                <Form >
                    <Form.Group controlId="name">
                        <Row className="w-100">
                            <Col md={3}>
                                <Form.Label className="text-nowrap"> Name  <StarOutlinedIcon style={{ color: "black", fontSize: "smaller" }} /></Form.Label>
                            </Col>
                            <Col md={4}>
                                <Form.Control className="profile-labels" name="name" value={profile.name} required type="text" onChange={(e) => handleUserChange(e)} placeholder="Name" />
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group controlId="contactNumber">
                        <Row className="w-100">
                            <Col md={3}>
                                <Form.Label className="text-nowrap"> Contact Number </Form.Label>
                            </Col>
                            <Col md={4}>
                                <Form.Control className="profile-labels" name="contactNumber" value={profile.contactNumber} type="text" onChange={(e) => handleUserChange(e)} placeholder="Contact Number" />
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group controlId="emailId">
                        <Row className="w-100">
                            <Col md={3}>
                                <Form.Label className="text-nowrap"> Email Id <StarOutlinedIcon style={{ color: "black", fontSize: "smaller" }} /></Form.Label>
                            </Col>
                            <Col md={4}>
                                <Form.Control className="profile-labels" name="emailId" value={profile.emailId} type="text" onChange={(e) => handleUserChange(e)} placeholder="Email Id" />
                            </Col>
                        </Row>
                    </Form.Group>
                    <Row><Divider className="m-2" sx={{ borderStyle: 'dotted', height: ' 5px', borderColor: 'black', borderWidth: '0px 0px 2px 0px' }} orientation="horizontal" /></Row>

                    <Form.Group controlId="businessName">
                        <Row className="w-100">
                            <Col md={3}>
                                <Form.Label className="text-nowrap"> Business Name  <StarOutlinedIcon style={{ color: "black", fontSize: "smaller" }} /></Form.Label>
                            </Col>
                            <Col md={4}>
                                <Form.Control className="profile-labels" name="businessName" value={baddress.businessName} required type="text" onChange={(e) => handleBusinessChange(e)} placeholder="Business Name" />
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group controlId="address">
                        <Row className="w-100">
                            <Col md={3}>
                                <Form.Label className="text-nowrap"> Address</Form.Label>
                            </Col>
                            <Col md={4}>
                                <Form.Control className="profile-labels" name="address" value={baddress.address} type="text" onChange={(e) => handleBusinessChange(e)} placeholder="Address" />
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group controlId="location">
                        <Row className="w-100">
                            <Col md={3}>
                                <Form.Label className="text-nowrap"> Location </Form.Label>
                            </Col>
                            <Col md={4}>
                                <Form.Control className="profile-labels" name="location" value={baddress.location} type="text" onChange={(e) => handleBusinessChange(e)} placeholder="Location" />
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group controlId="pincode">
                        <Row className="w-100">
                            <Col md={3}>
                                <Form.Label className="text-nowrap"> Pincode </Form.Label>
                            </Col>
                            <Col md={4}>
                                <Form.Control className="profile-labels" name="pincode" value={baddress.pincode} type="text" onChange={(e) => handleBusinessChange(e)} placeholder="Pincode" />
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group controlId="region">
                        <Row className="w-100">
                            <Col md={3}>
                                <Form.Label className="text-nowrap"> Region </Form.Label>
                            </Col>
                            <Col md={4}>
                                <Form.Control className="profile-labels" name="region" value={baddress.region} type="text" onChange={(e) => handleBusinessChange(e)} placeholder="Region" />
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group controlId="state">
                        <Row className="w-100">
                            <Col md={3}>
                                <Form.Label className="text-nowrap"> State </Form.Label>
                            </Col>
                            <Col md={4}>
                                <Form.Control className="profile-labels" name="state" value={baddress.state} type="text" onChange={(e) => handleBusinessChange(e)} placeholder="State" />
                            </Col>
                        </Row>
                    </Form.Group>
                    <div className="w-100 d-flex flex-row align-items-center justify-content-end">
                        <Button onClick={()=>updateProfileAddress()} className="ml-3 m-1 form-button">  Save </Button>
                    </div>
                </Form>

            </>}
        </Container >
    </>)
}
export default Profile;