import React, { useState, useMemo, useEffect, useContext, useRef } from "react";
import "./PlantTable.css"; // Importing the styles
import axios from "axios"; // Importing axios for API requests
import updownIcon from "../../assets/updown_icon.png";
import editIcon from "../../assets/edit-icon.png";
import filterIcon from "../../assets/filter-icon1.svg";
import settingIcon from "../../assets/Setting-icon.svg";
import searchIcon from "../../assets/search-icon.svg";
import { MessageContext } from "../../App";
import { Col, Form, OverlayTrigger, Popover, Row, Modal, Button } from "react-bootstrap";
import Paginationcontrol from "./PaginationControl";
import NotInterestedOutlinedIcon from '@mui/icons-material/NotInterestedOutlined';
import { useTranslation } from "react-i18next";

const PlantTable = ({ stateChanger,rowId }) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const searchInputRef = useRef(null); // Create a ref for the search bar
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State to manage dropdown visibility
  const [selectedColumns, setSelectedColumns] = useState({
    plantId: false,
    plantName: true,
    plantType: true,
    plantCategory: true,
    capacity: false,
    capacityUnit: false,
    country: true,
    region: false,
    state: false,
    district: false,
    pincode: false,
    longitude: false,
    latitude: false,
    installDate: false,
    azimuthAngle: false,
    tiltAngle: false,
    ownerFirstName: true,
    ownerLastName: true,
    ownerEmail: true,
    mobileNumber: false,
  });
  const [selectedFilter, setSelectedFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [selectedRows, setSelectedRows] = useState([]);
  const [tableProps, setTableProps] = useState({
    rowsPerPage: 5,
    currentPage: 1,
    pageFirst: 1,
    pageLast: 5,
  });

  const [data, setData] = useState([]);
  const [update, setUpdate] = useState(false);

  const [disableBox, setDisablekBox] = useState(false);
  const [enable, setEnable] = useState(false);

  const onMessageChange = useContext(MessageContext);
  const [showConfirmModal, setShowConfirmModal] = useState(false); // Modal visibility state
  const [selectedPlant, setSelectedPlant] = useState(null); // Selected plant for confirmation

  const downloadLink = () => {
    alert("Plant list download action");
  }

  // const fetchData = async () => {
  //   try {
  //     const entityId = JSON.parse(sessionStorage.getItem("userData"))?.entityId || "";
  //     console.log("Entity ID:", entityId);

  //     const authToken = sessionStorage.getItem("authToken");
  //     console.log("Auth Token:", authToken);

  //     if (!entityId) {
  //       console.warn("No entity ID found. Ensure session storage contains 'userData' with 'entityId'.");
  //       return;
  //     }

  //     // Fetching data from the API
  //     const response = await axios.get(
  //       `https://solar-api.antsai.in/api/plant/fetchPlantList?entityid=${entityId}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${authToken}`,
  //         },
  //       }
  //     );

  // Update fetchData function to fetch either active or disabled plants
  const fetchData = async () => {
    try {
      const entityId = JSON.parse(sessionStorage.getItem("userData"))?.entityId || "";
      const authToken = sessionStorage.getItem("authToken");
  
      if (!entityId) {
        console.warn("No entity ID found. Ensure session storage contains 'userData' with 'entityId'.");
        return;
      }
  
      // Toggle API endpoint based on disableBox
      const endpoint = disableBox
        ? `https://solar-api.antsai.in/api/plant/fetchDisabledPlants?entityid=${entityId}`
        : `https://solar-api.antsai.in/api/plant/fetchPlantList?entityid=${entityId}`;
  
      // Fetch data
      const response = await axios.get(endpoint, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
  
      console.log("API Response Status:", response.status);
      console.log("API Response Data:", response.data);
  
      if (Array.isArray(response.data) && response.data.length > 0) {
        const formattedData = response.data.map((item) => ({
          plantId: item["Plant ID"],
          plantName: item["Plant Name"],
          plantType: item["Plant Type"],
          plantCategory: item["Plant Category"],
          capacity: item["Capacity"],
          capacityUnit: item["Capacity Unit"],
          country: item["Country"],
          region: item["Region"],
          state: item["State"],
          district: item["District"],
          pincode: item["Pincode"],
          longitude: item["Longitude"],
          latitude: item["Latitude"],
          installDate: item["Install Date"],
          azimuthAngle: item["Azimuth Angle"],
          tiltAngle: item["Tilt Angle"],
          ownerFirstName: item["Owner First Name"],
          ownerLastName: item["Owner Last Name"],
          ownerEmail: item["Owner Email"],
          mobileNumber: item["Mobile Number"],
        }));
        setData(formattedData);
      } else {
        console.warn(
          disableBox
            ? "No deleted records found."
            : "No active records found."
        );
        setData([]); // Set empty array when no records are returned
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setData([]); // Set empty array in case of an error
    }
  };
  

// UseEffect to fetch data whenever `disableBox` state changes
useEffect(() => {
  fetchData();
}, [disableBox]);

  const handleColumnSelect = (column) => {
    if (column !== "plantName") {  // Prevent toggling for plantId and plantName
      setSelectedColumns((prev) => ({ ...prev, [column]: !prev[column] }));
    }
  };

  const handleSort = (column) => {
    let direction = "ascending";
    if (sortConfig.key === column && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key: column, direction });
  };

  // Sorting logic based on the updown icon click
  const sortedData = useMemo(() => {
    let sortableData = [...data];
    if (sortConfig.key) {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [data, sortConfig, searchQuery]);

  // Filtering logic (only filters if a filter is selected and a search query is entered)
  const filteredData = useMemo(() => {
    return sortedData.filter((row) => {
      if (!selectedFilter || !searchQuery) return true; // No filter if no filter or search query is provided
      return row[selectedFilter]
        .toLowerCase()
        .startsWith(searchQuery.toLowerCase()); // Filter matches only if it starts with the query
    })
  }, [update, sortConfig, searchQuery, sortedData]);

  // Pagination logic
  const currentRows = useMemo(() => {
    if (filteredData.length > 0) {
      return filteredData.slice(((tableProps.currentPage - 1) * tableProps.rowsPerPage), (((tableProps.currentPage - 1) * tableProps.rowsPerPage) + tableProps.rowsPerPage))
    }
    return []
  }, [filteredData, selectedRows, tableProps]);

  const tablePropsChange = (tableProps) => {
    setTableProps(tableProps);
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handling a maximum of 7 columns and hiding the rest in horizontal scrolling
  const visibleColumns = Object.keys(selectedColumns).filter(
    (key) => selectedColumns[key]
  );

  //handling filter and dropdown select toggle outside
  function handleAllToggleEvents() {
    isFilterVisible == true ? setIsFilterVisible(false) : "";
    isDropdownVisible == true ? setIsDropdownVisible(false) : ""
  }

  const disableRow = (e, plant) => {
    e.stopPropagation();
    setEnable(false)
    setSelectedPlant(plant); // Store selected plant for confirmation
    setShowConfirmModal(true); // Show confirmation modal
  };

  // Add enableRow function to handle enabling plants
const enableRow = (e, plant) => {
  e.stopPropagation();
  setEnable(true);
  setSelectedPlant(plant);
  setShowConfirmModal(true);
};

  const confirmDisable = async () => {
    try {
      const response = await fetch("https://solar-api.antsai.in/api/plant/disable", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
        },
        body: JSON.stringify({ plant_id: selectedPlant.plantId }),
      });

      if (response.ok) {
        onMessageChange({ type: "Success", info: "Plant disabled successfully." });
        setShowConfirmModal(false); // Close the modal on success
        // fetchData(); // Fetch updated data instead of reloading the page
        setTimeout(() => {
          window.location.reload();
        }, 1000); // 1-second delay
      } else {
        const errorData = await response.json();
        console.error("Failed to disable plant:", errorData);
        onMessageChange({ type: 'Danger', info: `Failed to disable plant: ${errorData.message || "Unknown error"}` });
        setShowConfirmModal(false); // Close the modal in case of error
      }
    } catch (error) {
      console.error("Error disabling plant:", error);
      onMessageChange({ type: "Warning", info: "An error occurred while disabling the plant. Please try again later." });
      setShowConfirmModal(false); // Close the modal in case of error
    }
  };

  const confirmEnable = async () => {
    try {
      const response = await fetch("https://solar-api.antsai.in/api/plant/enablePlant", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
        },
        body: JSON.stringify({ plant_id: selectedPlant.plantId }),
      });
  
      if (response.ok) {
        onMessageChange({ type: "Success", info: "Plant enabled successfully." });
        setShowConfirmModal(false);
        // fetchData();
        setTimeout(() => {
          window.location.reload();
        }, 1000); // 1-second delay
      } else {
        const errorData = await response.json();
        onMessageChange({ type: "Danger", info: `Failed to enable plant: ${errorData.message || "Unknown error"}` });
        setShowConfirmModal(false);
      }
    } catch (error) {
      onMessageChange({ type: "Warning", info: "An error occurred while enabling the plant. Please try again later." });
      setShowConfirmModal(false);
    }
  };

  const selectRows = (e, rowData) => {
    e.stopPropagation();
    const index = selectedRows.findIndex(r => r.id === rowData.id);
    { index === -1 ? setSelectedRows([...selectedRows, rowData]) : setSelectedRows([...selectedRows.filter(r => r.id !== rowData.id)]) }
  }

  const emptyRows = currentRows.length !== 0 ? currentRows.length : 0;

  const downloadCSV = () => {
    const csvRows = [];
    const headers = Object.keys(data[0] || {});
    csvRows.push(headers.join(","));

    for (const row of data) {
      const values = headers.map((header) => {
        const escaped = ("" + row[header]).replace(/"/g, '""'); // Escape double quotes by doubling them
        return `"${escaped}"`; // Wrap each value in quotes
      });
      csvRows.push(values.join(","));
    }

    const csvString = csvRows.join("\n");
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.setAttribute("download", "PlantsList.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

    
  // Handle filter selection
  const handleFilterSelect = (column) => {
    setSelectedFilter(column); // Set the selected filter
    setIsDropdownOpen(false); // Close the dropdown
    setTimeout(() => {
      if (searchInputRef.current) {
        searchInputRef.current.focus(); // Focus on the search bar
      }
    }, 0); // Ensure UI updates before focusing
  };

  const onRowChoose = (e, row) => {   
    rowId(row);
    stateChanger(6);
  };

  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <div className="main-container">
        <Row className="d-flex flex-row align-items-center justify-content-between my-4">

          <Col></Col>
          <Col className="gap-3 d-flex flex-row align-items-center justify-content-end" >
            <span className="downloadLink" onClick={downloadCSV}>
              Download
            </span>
            <OverlayTrigger
              rootClose
              trigger="click"
              placement="left-start"
              show={isDropdownOpen} // Control dropdown visibility
              onToggle={(isOpen) => setIsDropdownOpen(isOpen)} // Sync state with dropdown
              overlay={
                <Popover>
                  <Popover.Body className="overlay-body-menu">
                    <ul>
                      {Object.keys(selectedColumns)
                        .filter((key) => selectedColumns[key])
                        .map((column) => (
                          <li key={column} onClick={() => handleFilterSelect(column)}>
                            {column.charAt(0).toUpperCase() + column.slice(1)}
                          </li>
                        ))}
                    </ul>
                  </Popover.Body>
                </Popover>
              }
            >
              <img src={filterIcon} 
              alt="filter" 
              className="filter-icon"
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
               />
            </OverlayTrigger>
            <div style={{position:'relative'}} className=" w-auto">
              <input
              ref={searchInputRef} // Attach ref to the input
                type="text"
                style={{width: '341px',
                  height: '39px',
                  background: '#BBCCD9 0% 0% no-repeat padding-box',
                  borderRadius: '5px',
                  opacity:' 0.51'}}
                className="search-input"
                placeholder={`Search by ${selectedFilter || "..."}`}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                disabled={!selectedFilter}
              />
              <img src={searchIcon} alt="search" className="search-icon" />
            </div>
          </Col>
        </Row>
        <div className="border rounded border-light-subtle">
          <div className="table-container" style={{ overflowX: "auto" }}>
            <table style={{ width: "100%", whiteSpace: "nowrap" }}>
              <thead>
                <tr>
                  <th></th>
                  {Object.keys(selectedColumns)
                    .filter((key) => selectedColumns[key])
                    .map((column) => (
                      <th key={column} onClick={() => handleSort(column)}>
                        {column
                          .replace(/([A-Z])/g, " $1")
                          .replace(/^\w/, (c) => c.toUpperCase())} {/* Capitalizes the first letter */}
                        <img src={updownIcon} alt="sort" style={{ cursor: "pointer" }} />
                      </th>
                    ))}
                  <th>
                  <div className="gap-2 d-flex flex-row align-items-center justify-content-end px-2">
                    <OverlayTrigger
                      rootClose
                      trigger="click"
                      placement="auto-start"
                      overlay={
                        <Popover>
                          <Popover.Body className="overlay-body">
                            <div>
                              {Object.keys(selectedColumns).map((column) => (
                                <label key={column}>
                                  <input
                                    type="checkbox"
                                    checked={selectedColumns[column]}
                                    onChange={() => handleColumnSelect(column)}
                                    // disabled={column === "plantId" || column === "plantName"} // Disable for these columns
                                    disabled={column === "plantName"}
                                  />{" "}
                                  {column.charAt(0).toUpperCase() + column.slice(1)}
                                </label>
                              ))}
                                 <label>
                                  <input
                                    type="checkbox"
                                    checked={disableBox}
                                    onChange={() => setDisablekBox(!disableBox)} // Toggle disabled plants
                                  />{" "}
                                  Disabled Plants
                                </label>
                            </div>
                          </Popover.Body>
                        </Popover>
                      }
                    >
                    <div>
                      <img src={settingIcon} alt="settings" className="setting-icon" />
                      </div>
                    </OverlayTrigger>
                    </div>
                  </th>
                </tr>
              </thead>
              {/* <tbody>
                {currentRows.map((row) => (
                  <tr key={row.id}>
                    <td></td>
                    {Object.keys(selectedColumns)
                      .filter((key) => selectedColumns[key])
                      .map((column) => (
                        <td key={`${row.id}-${column}`}>{row[column]}</td>

                      ))}
                    <td>
                    <div className="gap-2 d-flex flex-row align-items-center justify-content-end px-2">
                     <img
                          src={editIcon}
                          alt="edit"
                          className="edit-icon"
                          onClick={(e) => onRowChoose(e, row.plantId)}
                        />
                       <NotInterestedOutlinedIcon
                          onClick={(e) => (disableBox ? enableRow(e, row) : disableRow(e, row))}
                          style={{ cursor: "pointer" }}
                          className={disableBox ? "enable-icon" : "disable-icon"} // Apply the appropriate class
                        /> 
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody> */}
              <tbody>
  {currentRows.length > 0 ? (
    currentRows.map((row) => (
      <tr key={row.plantId}>
        <td></td>
        {Object.keys(selectedColumns)
          .filter((key) => selectedColumns[key])
          .map((column) => (
            <td key={`${row.plantId}-${column}`}>{row[column]}</td>
          ))}
        <td>
          <div className="gap-2 d-flex flex-row align-items-center justify-content-end px-2">
            <img
              src={editIcon}
              alt="edit"
              className="edit-icon"
              onClick={(e) => onRowChoose(e, row.plantId)}
            />
            <NotInterestedOutlinedIcon
              onClick={(e) =>
                disableBox ? enableRow(e, row) : disableRow(e, row)
              }
              style={{ cursor: "pointer" }}
              className={disableBox ? "enable-icon" : "disable-icon"}
            />
          </div>
        </td>
      </tr>
    ))
  ) : (
    <tr>
      <td
        colSpan={Object.keys(selectedColumns).length + 2}
        style={{
          textAlign: "center",
          padding: "20px",
          fontSize: "16px",
          color: "#666",
        }}
      >
        {disableBox
          ? "No deleted records found for this Plant."
          : "No active records found for this Plant."}
      </td>
    </tr>
  )}
</tbody>
            </table>
            {filteredData.length > 0 ? <Paginationcontrol
              rowsCount={filteredData.length}
              tableProps={tableProps}
              tablePropsChange={tablePropsChange}
              alwaysShown={true}
            /> : <></>}
          </div>
        </div>
      </div>
            {/* Confirmation Modal */}
            <div className="d-flex flex-column align-items-center justify-content-center">
      <Modal
          show={showConfirmModal}
          onHide={() => setShowConfirmModal(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{`${enable? 'Enable' :'Disable'} Plant ?`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
           {`Are you sure you want to ${enable? 'Enable' :'Disable'} the Plant ${selectedPlant?.plantName} ?`}
          </Modal.Body>
          <Modal.Footer>
            <button
              className="modal-button"
              onClick={() => setShowConfirmModal(false)}
              autoFocus // "No" as default focus
            >
              No
            </button>
            <button className="modal-button" onClick={() => enable ? confirmEnable() : confirmDisable()}>
              Yes
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default PlantTable;