import React, { useState, useEffect } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "./antsfp.css";
import backgroundImage from "../assets/solar-dl-login-bg-2x.png";
import logo from "../assets/logog.svg";
import { useNavigate } from "react-router-dom";

const SetYourPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const [passwordValidationError, setPasswordValidationError] = useState(false);
  const [isPasswordUpdated, setIsPasswordUpdated] = useState(false);
  const [apiError, setApiError] = useState("");

  const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);

  const navigate = useNavigate();
  const [token, setToken] = useState("");

  useEffect(() => {
    // Extract the full query string from the URL
    const queryParams = window.location.search;

    // Remove the leading "?" and treat the rest as the token
    if (queryParams.startsWith("?")) {
      const extractedToken = queryParams.substring(1); // Remove "?" from the start
      setToken(extractedToken); // Save the extracted token
    } else {
      setApiError("Invalid or missing token in URL.");
    }
  }, []);

  const toggleNewPasswordVisibility = () => {
    setIsNewPasswordVisible(!isNewPasswordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();

    // Correct Password Validation Regex
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\-=/\\]).{8,15}$/;

    // Check if new password matches the criteria
    if (!passwordRegex.test(newPassword)) {
      setPasswordValidationError(true);
      return;
    }

    setPasswordValidationError(false);

    if (newPassword !== confirmPassword) {
      setPasswordMatchError(true);
      return;
    }

    setPasswordMatchError(false);

    // Send the token and password to the backend
    try {
      const response = await fetch(
        "https://solar-api.antsai.in/api/reset-password",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: token, // Pass the extracted token
            password: newPassword,
          }),
        }
      );

      if (response.ok) {
        setIsPasswordUpdated(true);
      } else {
        const errorData = await response.json();
        setApiError(errorData.message || "Failed to reset password.");
      }
    } catch (error) {
      setApiError("An error occurred while resetting the password.");
      console.error(error);
    }
  };

  const handleBackToLogin = () => {
    navigate("/login"); // Adjust the path based on your app's routing
  };

  return (
    <div
      className="forgotp-card-container"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="forgotp-card">
        <img src={logo} className="forgotp-logo" alt="Logo" />
        <h2 className="forgotp-title">
          <span>
            {isPasswordUpdated ? "Password Updated" : "Set Your Password"}
          </span>
        </h2>
        {isPasswordUpdated ? (
          <div style={{ textAlign: "center" }}>
            <p>Your password has been successfully updated!</p>
            <button
              onClick={handleBackToLogin}
              className="forgotp-submit-button"
            >
              Back to Login
            </button>
          </div>
        ) : (
          <form
            onSubmit={handlePasswordSubmit}
            className="forgotp-password-form"
          >
            {apiError && <p className="forgotp-error-message">{apiError}</p>}
            <div className="forgotp-input-container">
              <label className="forgotp-input-label">New Password</label>
              <div style={{ position: "relative" }}>
                <input
                  type={isNewPasswordVisible ? "text" : "password"}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                  placeholder="Enter new password"
                  className="forgotp-input"
                />
                <span
                  onClick={toggleNewPasswordVisibility}
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "42%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                    color: "#888",
                  }}
                >
                  {isNewPasswordVisible ? <FaEye /> : <FaEyeSlash />}
                </span>
              </div>
            </div>
            <div className="forgotp-input-container">
              <label className="forgotp-input-label">Confirm Password</label>
              <div style={{ position: "relative" }}>
                <input
                  type={isConfirmPasswordVisible ? "text" : "password"}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                  placeholder="Confirm new password"
                  className="forgotp-input"
                />
                <span
                  onClick={toggleConfirmPasswordVisibility}
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "42%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                    color: "#888",
                  }}
                >
                  {isConfirmPasswordVisible ? <FaEye /> : <FaEyeSlash />}
                </span>
              </div>
            </div>
            {passwordValidationError && (
              <p className="forgotp-error-message">
                Password must be 8-15 characters, contain at least one uppercase
                letter, one lowercase letter, one number, and one special
                character.
              </p>
            )}
            {passwordMatchError && (
              <p className="forgotp-error-message">
                Passwords do not match. Please try again.
              </p>
            )}
            <button type="submit" className="forgotp-submit-button">
              Set New Password
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default SetYourPassword;
