import { useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import InfoIcon from '@mui/icons-material/Info';
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";


function MyQueries() {
    function Styles() {
        return <style>{`
            .form-button{
                   background-color: #167db0;
                   color: white;
                   border-radius: 7px;
                   padding: 3px 20px 3px 20px;
                    font-family: 'Open Sans';
                    font-weight: bold;
                }

            .form-button:hover{
                background-color: white !important;
                color : black !important;
                border-color:#167db0 !important;
                }

            .form-button-sp{
                    padding: 1px 10px 1px 10px !important;
                    border-radius: 20px !important;
                }
            .myqueries {
              font-family: 'Open Sans';
                    font-weight: bold;
                        margin-top: 50px;
            }    
             .queries-labels{
             font-weight: normal !important;
             } 
            .myqueries .table{
              font-family: 'Open Sans';
                    font-weight: bold;
                   border: 2px solid #D4DAE4;
            }    
                    .myqueries .table .tbody .tr .td{
                    border:0px !important;
                    }
              .myqueries .table .tbody .tr {
                    border:0px !important;
                    }
             .myqueries .row {
             margin-bottom:5px;
             }
             .queries-form div{
              margin-top:5px;
              margin-bottom:5px;
             }
            `}</style>
    }
    const [queriesColumn, setQueriesColumn] = useState(["id", "Query No", "Query Type", "Description", "Status"])
    const [queries, setQueries] = useState([
        { "id": 1, "Query No": "Q1002", "Query Type": "Not Working", "Description": "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam remSed ut perspiciatis unde omnis iste ", "Status": "Completed" },
        { "id": 2, "Query No": "Q1003", "Query Type": "Not Working", "Description": "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam remSed ut perspiciatis unde omnis iste ", "Status": "Pending" },
        { "id": 3, "Query No": "Q1004", "Query Type": "Not Working", "Description": "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam remSed ut perspiciatis unde omnis iste ", "Status": "Information" },
        { "id": 4, "Query No": "Q1005", "Query Type": "Not Working", "Description": "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam remSed ut perspiciatis unde omnis iste ", "Status": "Completed" }
    ])
    const [editQueries, setEditQueries] = useState({ ourAddress: '', name: "", email: '', phone: '', businessName: '', website: '', subject: '', message: '' });

    const [edit, setEdit] = useState(false);

    const handleQueriesChange = (e) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.name;

        setEditQueries((prevData) => ({ ...prevData, [name]: value, }));
    }

    const addNewQueries=()=>{
        setEdit(false)
    }

    return (<>
        <Styles />
        {!edit ?
            (
                <Container className="myqueries">
                    <Row className="mt-2 mb-2">
                        <Col className="d-flex flex-row align-items-center justify-content-start">
                            <div>Queries</div>
                        </Col>
                        <Col className="d-flex flex-row align-items-center justify-content-end">
                            <Button onClick={() => setEdit(true)} className="ml-3 m-1 form-button">  New Queries </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table striped bordered hover>
                                <thead>
                                    <tr className="border-0">
                                        {queriesColumn.map((q) => (
                                            <td className="py-4 px-5 border-0">{q}</td>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody >
                                    {queries.map((r) => (
                                        <tr key={r.id + "queries"} className="border-0">
                                            {/* {queriesColumn.map((q)=>( */}

                                            <td className="py-2 px-5 border-0">{r['id']}</td>
                                            <td className="py-2 px-5 border-0"><div style={{ color: "#F41313" }}>{r['Query No']}</div></td>
                                            <td className="py-2 px-5 fw-normal border-0">{r['Query Type']}</td>
                                            <td className="py-2 px-5 fw-normal border-0"><p style={{ width: '100%', height: '50px', textWrap: 'auto', overflow: 'auto' }}>{r['Description']}</p></td>
                                            <td className="py-2 px-5 fw-normal border-0">{r['Status'] === 'Completed' ? <CheckCircleIcon sx={{ color: 'green' }} /> : r['Status'] === 'Information' ? <InfoIcon sx={{ color: '#F8B61D' }} /> : <RemoveCircleIcon sx={{ color: '#F04C3F' }} />}</td>
                                            {/* ))} */}
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
            )
            : (<Container className="gap-2 myqueries">
                <Form  className="gap-2 queries-form">
                    <Row className="w-100">
                        <Col md={2}>
                            <Form.Label className="text-nowrap"> Queries </Form.Label>
                        </Col>
                        <Col md={3}>
                           
                        </Col>
                    </Row>
                    <Form.Group controlId="ourAddress">
                        <Row className="w-100">
                            <Col md={2}>
                                <Form.Label className="text-nowrap"> Our Address  <StarOutlinedIcon style={{ color: "black", fontSize: "smaller" }} /></Form.Label>
                            </Col>
                            <Col md={3}>
                                <Form.Control className="queries-labels" style={{height:'100px'}} name="ourAddress" value={editQueries.ourAddress}  type="text" as="textarea" onChange={(e) => handleQueriesChange(e)} />
                            </Col>
                        </Row>
                    </Form.Group>
                    <Row className="w-100">
                        <Col md={2}>
                            <Form.Label className="text-nowrap"> Enquiry Form </Form.Label>
                        </Col>
                        <Col md={3}>
                           
                        </Col>
                    </Row>
                    <Form.Group controlId="name">
                        <Row className="w-100">
                            <Col md={2}>
                                <Form.Label className="text-nowrap">Name <StarOutlinedIcon style={{ color: "black", fontSize: "smaller" }} /></Form.Label>
                            </Col>
                            <Col md={3}>
                                <Form.Control className="queries-labels" name="name" value={editQueries.name} type="text" onChange={(e) => handleQueriesChange(e)} />
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group controlId="email">
                        <Row className="w-100">
                            <Col md={2}>
                                <Form.Label className="text-nowrap"> Email <StarOutlinedIcon style={{ color: "black", fontSize: "smaller" }} /></Form.Label>
                            </Col>
                            <Col md={3}>
                                <Form.Control className="queries-labels" name="email" value={editQueries.email} type="text" onChange={(e) => handleQueriesChange(e)} />
                            </Col>
                        </Row>
                    </Form.Group>
                   
                   <Form.Group controlId="phone">
                        <Row className="w-100">
                            <Col md={2}>
                                <Form.Label className="text-nowrap">Phone <StarOutlinedIcon style={{ color: "black", fontSize: "smaller" }} /></Form.Label>
                            </Col>
                            <Col md={3}>
                                <Form.Control className="queries-labels" name="phone" value={editQueries.phone} required type="text" onChange={(e) => handleQueriesChange(e)}  />
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group controlId="businessName">
                        <Row className="w-100">
                            <Col md={2}>
                                <Form.Label className="text-nowrap"> Business Name</Form.Label>
                            </Col>
                            <Col md={3}>
                                <Form.Control className="queries-labels" name="businessName" value={editQueries.businessName} type="text" onChange={(e) => handleQueriesChange(e)} />
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group controlId="website">
                        <Row className="w-100">
                            <Col md={2}>
                                <Form.Label className="text-nowrap"> Website </Form.Label>
                            </Col>
                            <Col md={3}>
                                <Form.Control className="queries-labels" name="website" value={editQueries.website} type="text" onChange={(e) => handleQueriesChange(e)}  />
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group controlId="subject">
                        <Row className="w-100">
                            <Col md={2}>
                                <Form.Label className="text-nowrap"> Subject </Form.Label>
                            </Col>
                            <Col md={3}>
                                <Form.Control className="queries-labels" name="subject" value={editQueries.subject} type="text" onChange={(e) => handleQueriesChange(e)}  />
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group controlId="message">
                        <Row className="w-100">
                            <Col md={2}>
                                <Form.Label className="text-nowrap"> Message </Form.Label>
                            </Col>
                            <Col md={3}>
                                <Form.Control className="queries-labels" name="message" value={editQueries.message} type="text" onChange={(e) => handleQueriesChange(e)} />
                            </Col>
                        </Row>
                    </Form.Group>
                    
                    <div className="w-100 d-flex flex-row align-items-center justify-content-end">
                        <Button onClick={() => addNewQueries()} className="ml-3 m-1 form-button">  Save </Button>
                    </div>
                </Form>
            </Container>)
        }
    </>)
}
export default MyQueries;