import React, { createContext, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import Login from "./login/Login";  
// import Signup from "./signup/Signup";
import Otp from "./forgotpassword/Otp";
import FP from "./forgotpassword/antsfp";
import ResetPassword from "./forgotpassword/setYourPassword";
// import Details from "./signup/details/Details";
import Dashboard from "./dashboard/overview/Dashboard";
import Plants from "./dashboard/plants/Plants";
import Maintenance from "./dashboard/maintenance/Maintenance";
import Analytics from "./dashboard/analytics/Analytics";
import Reports from "./dashboard/reports/Reports";
import "bootstrap/dist/css/bootstrap.min.css";
import PlantStausPage from "./dashboard/plants/plantstatus/PlantStatusPage";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import PlantAddPage from "./dashboard/plants/NewAddPlant";
import User from "./admin/user/User";
import Entity from "./admin/entity/Entity";
import EntityAddPage from "./admin/entity/EntityAdd";
import EntityEditPage from "./admin/entity/EntityEdit";
import EditPlant from "./dashboard/plants/EditPlant";
import NewTicket from "./dashboard/maintenance/NewTicket";
import UserAddPage from "./admin/user/UserAdd";
import AdminPlantAdd from "./admin/plant/PlantAdd";
import AdminPlantEdit from "./admin/plant/PlantEdit";
import MyNotification from "./MyNotification";
import { ToastContainer } from "react-bootstrap";
import Layout from "./Layout";
import Profile from "./dashboard/navbar/Profile";
import ChangePassword from "./dashboard/navbar/ChangePassword";
import MyQueries from "./dashboard/navbar/MyQueries";
import ContactUs from "./dashboard/navbar/ContactUs";
import SetYourPassword from "./forgotpassword/setYourPassword";
import SolarNavbar from "./dashboard/navbar/SolarNavbar";
import SolarAdminNavbar from "./admin/navbar/SolarAdminNavbar";

export const MessageContext = createContext();

function App() {
  const [notifications, setNotifications] = useState([]);

  function updateNotification(message) {
    const n = {
      id: notifications.length + 3,
      title: `${
        message.type === "Success"
          ? "Success"
          : message.type === "Info"
          ? "Information"
          : "Warning"
      }`,
      type: message.type,
      message: message.info,
    };
    setNotifications([...notifications, n]);
  }

  function removeNotification(id) {
    setNotifications(notifications.filter((n) => n.id !== id));
  }

  const footerBarStyle = {
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
    backgroundColor: "#f8f9fa",
    color: "#343a40",
    textAlign: "center",
    boxShadow: "0 -1px 5px rgba(0, 0, 0, 0.1)",
    zIndex: 1000,
    padding: "0.5rem 0",
  };

  const notificationContainerStyle = {
    width: "28%",
    maxWidth: "300px",
    fontSize: "0.9rem",
  };

  return (
    <MessageContext.Provider value={updateNotification}>
      <Router>
        <AppWrapper
          notifications={notifications}
          removeNotification={removeNotification}
          footerBarStyle={footerBarStyle}
          notificationContainerStyle={notificationContainerStyle}
        />
      </Router>
    </MessageContext.Provider>
  );
}

function AppWrapper({
  notifications,
  removeNotification,
  footerBarStyle,
  notificationContainerStyle,
}) {
  const location = useLocation();

  // Define routes that should not display the footer or padding
  const noFooterRoutes = [
    "/login",
    // "/signup/Signup",
    "/forgotpassword/Otp",
    "/forgotpassword/antsfp",
    "/forgotpassword/setYourPassword",
  ];

  return (
    <div style={!noFooterRoutes.includes(location.pathname) ? { paddingBottom: "3rem" } : {}}>
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />
        {/* <Route path="/signup/Signup" element={<Signup />} /> */}
        <Route path="/forgotpassword/Otp" element={<Otp />} />
        <Route path="/forgotpassword/antsfp" element={<FP />} />
        <Route path="/forgotpassword/setYourPassword" element={<SetYourPassword />} />
        {/* <Route path="signup/details/Details" element={<Details />} /> */}
        {/* element={<Layout component={<CaseForm />} />}  */}
        <Route path="/dashboard/overview" element={<div><SolarNavbar/> <Layout component={<Dashboard />}/></div>} />
        <Route path="/dashboard/plants/Plants"  element={<div><SolarNavbar/><Layout component={<Plants />} /></div>} />
        <Route path="/admin" element={<div><SolarAdminNavbar/><Layout component={<Entity />} /></div>} />
        <Route path="/admin/entity/add" element={<div><SolarAdminNavbar/><Layout component={<EntityAddPage />} /></div>} />
        <Route path="/admin/entity/edit" element={<div><SolarAdminNavbar/><Layout component={<EntityEditPage />} /></div>} />
        <Route path="/admin/plant/add" element={<div><SolarAdminNavbar/><Layout component={<AdminPlantAdd />} /></div>} />
        <Route path="/admin/plant/edit" element={<div><SolarAdminNavbar/><Layout component={<AdminPlantEdit />} /></div>} />
        <Route path="/admin/user" element={<div><SolarAdminNavbar/><Layout component={<User />} /></div>} />
        <Route path="/admin/user/add" element={<div><SolarAdminNavbar/><Layout component={<UserAddPage />} /></div>} />
        <Route path="/dashboard/maintenance" element={<div><SolarNavbar/><Layout component={<Maintenance />} /></div>} />
        <Route path="/dashboard/analytics" element={<div><SolarNavbar/><Layout component={<Analytics />} /></div>} />
        <Route path="/dashboard/reports" element={<div><SolarNavbar/><Layout component={<Reports />} /></div>} />
        <Route path="/dashboard/plants/plantStatus" element={<div><SolarNavbar/><Layout component={<PlantStausPage />} /></div>} />
        <Route path="/dashboard/plants/addplant" element={<div><SolarNavbar/><Layout component={<PlantAddPage />} /></div>} />
        <Route path="/dashboard/plants/editplant" element={<div><SolarNavbar/><Layout component={<EditPlant />}/></div>} />
        <Route path="/dashboard/maintenance/newticket" element={<div><SolarNavbar/><Layout component={<NewTicket />} /></div>} />
        <Route path="/dashboard" element={<div><SolarNavbar/><Navigate to="/dashboard/overview" /></div>} />
        <Route path="/profile" element={<div><SolarNavbar/><Layout component={<Profile/>} /></div>} />
        <Route path="/password" element={<div><SolarNavbar/><Layout component={<ChangePassword/>} /></div>} />
        <Route path="/queries" element={<div><SolarNavbar/><Layout component={<MyQueries/>} /></div>} />
        <Route path="/contact" element={<div><SolarNavbar/><Layout component={<ContactUs/>} /></div>} />
      </Routes>
      <ToastContainer
        key={"notification-manager123"}
        className="p-3"
        position="bottom-end"
        aria-atomic="true"
        aria-live="polite"
        style={notificationContainerStyle}
      >
        {notifications.map((n, index) => (
          <MyNotification
            key={`${index + n.message}`}
            note={n}
            index={index}
            remove={removeNotification}
            length={notifications.length}
          />
        ))}
      </ToastContainer>
      {/* Conditionally render footer */}
      {!noFooterRoutes.includes(location.pathname) && (
        <div style={footerBarStyle}>
          <span>© 2024, GSAi. All rights reserved.</span>
        </div>
      )}
    </div>
  );
}

export default App;
