import React, { createContext, useContext, useState } from "react";
import logog from "./../../assets/logog.svg"; // Adjust path as necessary
import profileIcon from "./../../assets/profile_icon.png"; // Adjust path as necessary
import NTNotification from "./../../assets/NTNotification.svg"; // Adjust path as necessary
import { Container, Navbar, Nav, Popover, OverlayTrigger, Card, Stack,} from "react-bootstrap";
import "./Navbar.css";
import {useNavigate } from "react-router-dom";

function SolarAdminNavbar() {
  const navigate = useNavigate();
  function profileManagment() {
    navigate("/admin");
  }
  
  function Styles() {
    return (
      <style>{`
            .profile-admin : hover{
                color: grey !important;
                background
            }
            `}</style>
    );
  }

  function logout() {
    // Clear any necessary session or local storage data here
    navigate("/login");
  }

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className=" w-100 shadow-lg solor-navbar"
    >
      <Container className="ml-o mr-0 m-0 mw-100">
        <Navbar.Brand href="/">
          <div className="">
            <img src={logog} alt="Logo" />
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto navbar-links flex-fill justify-content-center"></Nav>
          <Nav>
            <div className="navbar-icons">
              {/* <LanguageUtility /> */}
              <img
                src={NTNotification}
                alt="Bell Icon"
                className="navbar-bell-icon"
              />
              <OverlayTrigger
                trigger="click"
                placement="bottom"
                rootClose
                overlay={<Popover className="shadow border" style={{position:"relative", width: "200px" }}>
                  <Popover.Body className="p-0 m-0">
                    <Card className="border-0 p-0 m-0">
                      <Styles />
                      <Card.Body>
                        <Stack direction="vertical" gap={1}>
                          <div
                            className="fw-bold profile-admin"
                            style={{ cursor: "pointer" }}
                            onClick={()=>navigate('/profile')}
                          >
                            Profile
                          </div>
                          <div
                            className="fw-bold profile-admin"
                            onClick={() => profileManagment()}
                            style={{ cursor: "pointer" }}
                          >
                            Administrator
                          </div>
                          <div
                            className="fw-bold profile-admin"
                            style={{ cursor: "pointer" }}
                            onClick={()=>navigate('/password')}
                          >
                            Change Password
                          </div>
                          <div
                            className="fw-bold profile-admin"
                            style={{ cursor: "pointer" }}
                            onClick={()=>navigate('/queries')}
                          >
                           My Queries
                          </div>
                          <div
                            className="fw-bold profile-admin"
                            style={{ cursor: "pointer" }}
                            onClick={()=>navigate('contact')}
                          >
                            Contact Us
                          </div>
                          <div
                            className="fw-bold profile-admin"
                            onClick={logout}
                            style={{ cursor: "pointer" }}
                          >
                            Logout
                          </div>
                        </Stack>
                      </Card.Body>
                      <Card.Footer style={{ color: "#708496" }}>
                        App Version 3.02
                      </Card.Footer>
                    </Card>
                  </Popover.Body>
                </Popover>}
              >
                <img
                  src={profileIcon}
                  alt="Profile Icon"
                  className="navbar-profile-icon"
                />
              </OverlayTrigger>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default SolarAdminNavbar;